import Core, { DataProvider } from 'testania';
import { selectTestania } from 'redux/testania/selectors';
import { setTestaniaName, setOnboardingConfig } from 'redux/testania/actions';
// TODO: fix in https://app.asana.com/0/1201994806276539/1205107468358935/f
// eslint-disable-next-line import/named
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import config from 'config';

import { selectUser } from 'redux/user/selectors';
import { setCountry } from 'redux/user/actions';
import { hidePayPal, setAppLoader } from 'redux/uiEffects/actions';
import { setIsRedesignedFlow } from 'redux/redesignedFlow/actions';
import { setCurrentProduct } from 'redux/payment/actions';
import { selectUrlParams } from 'redux/onboadring/selectors';
import { initAutoLogin, setOnboardingScreensList } from 'redux/onboadring/actions';

import { COUNTRIES_LIST, DEFAULT_COUNTRY } from 'constants/countriesList';

import { isRedesignedFlow } from 'helpers/redesignedFlow';
import { LS_KEYS } from 'helpers/localStorage';
import {
    getScreenList,
    getIsLogin,
    getUserStatus,
    setCurrentBranchToStore,
    setCompleteOBsToStore,
    setTestaniaLoginStatusToStore,
    setInitMeasure,
    isInstructionPage,
    clearCompleteOBsToStore,
} from './config/helpers';

import { validateFlow } from './config/flowValidation';
import { TESTANIA_INIT_CONFIG } from './config/flowInitConfig';

function InitTestania(props) {
    const [startStatus, setStartStatus] = useState(false);
    const searchParams = props.history.location.search;

    const { isAutologinComplete } = useSelector(selectUser);
    const { testaniaLoginStatus, completeOBs, testaniaName } = useSelector(selectTestania);
    const urlParams = useSelector(selectUrlParams);

    const dispatch = useDispatch();

    const token = localStorage.getItem(LS_KEYS.token) || null;
    const isResetStore = localStorage.getItem('isResetStore') || null;
    const isSkipStartPage = urlParams?.['skipStartPage'] === 'true';

    if (isResetStore) {
        dispatch({ type: 'RESET_STATE' });
    }

    const toNextPage = (path) => {
        props.history.push({
            pathname: path,
            search: searchParams,
        });
    };
    const initTestania = async () => {
        if (window.testaniaData) {
            const { config = null, country = '' } = await window.testaniaData;

            Core.setCurrentConfig(config);
            Core.setCountry(country);
        }

        const responseFromTestania = await Core.init(TESTANIA_INIT_CONFIG);

        if (responseFromTestania) {
            const { country: countryFromTestania, testName } = responseFromTestania;

            dispatch(setAppLoader(false));
            localStorage.setItem(LS_KEYS.testaniaResponseStatus, '1');

            const countryCodeList = COUNTRIES_LIST.map((el) => el.code);

            const country = countryCodeList.includes(countryFromTestania)
                ? countryFromTestania
                : localStorage.getItem('country') || DEFAULT_COUNTRY;

            if (country) {
                dispatch(setCountry(country));
                await setInitMeasure({ country, dispatch });
            }
            if (testName) {
                await dispatch(setTestaniaName(testName));

                isRedesignedFlow(testName) && dispatch(setIsRedesignedFlow(true));
            }
        }

        responseFromTestania?.callback();
    };

    const provideData = async () => {
        await DataProvider.provideInitialData({
            loginStatus: await getIsLogin(testaniaLoginStatus, token),
            startStatus,
            startAction: async () => setStartStatus(true),
            toNextPage,
            pathTestaniaPaidFinish: 'registration-page',
            pathTestaniaRegistrationFinish: 'thank-you',
            // TODO: need to add param is_password_set from BE
            getUserStatus: () => getUserStatus(dispatch, token),
            getToken: async () => token,
            getCompleteOBs: async () => completeOBs,
            getCurrentBranch: async () => testaniaName,
            setCurrentBranch: async (branch) => setCurrentBranchToStore(branch, dispatch),
            setCompleteOBs: async (branch) => setCompleteOBsToStore(branch, dispatch),
            clearCompleteOBs: () => clearCompleteOBsToStore(dispatch),
            setLoginStatus: async (status) => setTestaniaLoginStatusToStore(status, dispatch),
            customFilter: validateFlow,
            isSkipStartPage,
        });
    };

    const initTestaniaDependencies = async () => {
        await provideData();
        await initTestania();

        const onboardingFlow = DataProvider.getOnboardingFlow();

        const screenList = getScreenList(onboardingFlow);

        dispatch(setOnboardingScreensList(screenList));
        dispatch(setOnboardingConfig(onboardingFlow));
    };

    useEffect(() => {
        provideData();
    }, [token, completeOBs, testaniaName]);

    useEffect(() => {
        dispatch(initAutoLogin());
        dispatch(setCurrentProduct(null));
        dispatch(hidePayPal(config?.HIDE_PAYPAL || 'false'));
    }, []);

    useEffect(() => {
        if (isAutologinComplete && !isInstructionPage()) {
            try {
                initTestaniaDependencies();
            } catch (e) {
                console.error(
                    `Error with init testania, check initial methods in - initTestaniaDependencies \n \n ${e}`
                );
            }
        }
    }, [isAutologinComplete]);

    return null;
}

export default withRouter(InitTestania);
